import React from "react";

interface ModalProps {
  visible: boolean;
  onClose: () => void;
  children: React.ReactNode;
}

const Modal: React.FC<ModalProps> = ({ visible, onClose, children }) => {
  if (!visible) return null;

  const handleOverlayClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    if (event.target === event.currentTarget) {
      onClose();
    }
  };

  return (
    <div
      className="fixed h-full w-full inset-0 bg-black  bg-opacity-50 z-50 flex justify-center items-center"
      onClick={handleOverlayClick}
    >
      <div
        className="relative bg-slate-50 dark:bg-muted-1000 flex justify-center gap-5 flex-col h-1/2 w-4/5 md:w-2/3 xl:w-1/2 p-5 rounded-lg"
        onClick={(e) => e.stopPropagation()}
      >
        {children}
        <button
          onClick={onClose}
          className="absolute top-3 right-3 text-black  rounded"
        >
          <i className="iconify block mr-1 h-8 w-8 dark:color" data-icon="iconamoon:close-thin" />
        </button>
      </div>
    </div>
  );
};

export default Modal;
