import React, { useState } from "react";
import Modal from "@components/base/Modal";
import { RedirectStore } from "@components/content/RedirectStore";
import { getLangFromUrl, useTranslations } from "src/i18n/utils";

const GoToAppButton: React.FC = () => {
  const [showModal, setShowModal] = useState(false);

  const url = new URL(window.location.href);
  const lang = getLangFromUrl(url);
  const t = useTranslations(lang);
  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <div>
      <button
        className="relative font-normal inline-flex items-center justify-center leading-5 no-underline space-x-1 text-white bg-primary-500 h-12 px-5 py-3 text-sm rounded-xl hover:bg-primary-600 hover:shadow-xl hover:shadow-primary-500/20 tw-accessibility transition-all duration-300 w-full md:w-[240px] lg:w-[150px]"
        onClick={handleOpenModal}
      >
        {t("downloadButton.text")}
      </button>
      <Modal visible={showModal} onClose={handleCloseModal}>
        <RedirectStore />
      </Modal>
    </div>
  );
};

export default GoToAppButton;
